@tailwind base;
@tailwind components;
@tailwind utilities;

/*
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
*/

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
   /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
  }
}


.loading {
    @apply text-center mt-4;
    svg, i {
        @apply text-[27px] text-peach mb-1;
    }
    span {
        @apply block;
    }
}

body {
    @apply bg-body;
}

#root {
    @apply relative overflow-hidden min-h-screen;
}

* {
    @apply font-raleway;
}


h1 {
    @apply text-[32px] lg:text-[40px] leading-relaxed;
}

h2 {
    @apply text-[22px] lg:text-[28px] leading-tight;
}

.btn {
    padding: 16px 18px;
    @apply inline-block bg-peach text-white text-center font-bold text-[14px] tracking-wide uppercase border border-peach rounded-[10px] w-full hover:bg-peach-hover hover:border-peach-hover;
    &.btn-white {
        @apply bg-white text-gray border-white w-full hover:bg-border hover:border-border;
    }
    &.btn-beige {
        @apply bg-beige text-black border-beige hover:bg-skin hover:border-skin;
    }
    &.btn-skin {
        @apply bg-skin text-peach border-skin hover:bg-skin hover:border-skin;
    }
    &.icon {
        i, svg {
            @apply mr-2;
        }
    }
}

.btn-link {
    @apply text-peach font-bold;
    &.icon {
        i, svg {
            @apply mr-2;
        }
    }
}

.alert {
    padding: 6px 14px;
    margin-top: 1rem;
    font-size: 13px;
    @apply text-center bg-red/[.1] text-red border border-red/[.2] rounded-[10px] w-full;
    &.validate {
        @apply bg-green/[.1] text-green border-green/[.2];
    }
    &.informations {
        @apply bg-peach/[.1] text-peach border-peach/[.2];
    }
}

.topBloc {
    @apply w-screen lg:w-full mb-6 p-5 md:p-7 lg:p-0 lg:pt-10 flex items-center justify-start flex-wrap z-10;
    .leftSide {
        @apply flex flex-1 truncate items-center justify-start flex-wrap;
        .return {
            @apply cursor-pointer mr-2 text-[13px] md:text-[15px];
        }
        h1 {
            @apply truncate cursor-pointer text-[20px] md:text-[25px] lg:text-[30px] font-medium;
        }
    }
    .rightSide {
        @apply ml-auto pl-4 flex items-center justify-end flex-wrap whitespace-nowrap;
        .IconFavoris {
            @apply mr-2 lg:mr-0;
        }
    }
    &.sticked {
        @apply max-lg:sticky max-lg:top-0;
    }
    &.background {
        @apply max-lg:bg-skin
    }
    &.overlay {
        @apply absolute bg-gradient-blacktonone lg:max-w-inherit lg:w-full;
        .leftSide {
            .return {
                @apply text-white;
            }
            h1 {
                @apply text-white;
            }
        }
    }
}

.IconProfil {
    @apply w-[35px] h-[35px] bg-border rounded-full border-2 border-white cursor-pointer text-center leading-[33px] text-[12px] text-borderfocused;
}

.IconFavoris {
    @apply text-[20px] w-[35px] h-[35px] bg-white rounded-full text-center leading-[38px] cursor-pointer;
    &.active {
        @apply text-red;
    }
    &.userFavoris {
        @apply w-auto text-[14px] px-3 font-medium;
        svg {
            @apply mr-[6px] text-[13px] text-red;
        }
    }
}

.content {
    @apply lg:max-w-[1100px] lg:w-[70vw] lg:px-5 lg:mx-auto;
}

.cta {
    @apply flex items-center justify-start;
    a {
        @apply flex items-center justify-start;
    }
    .play-icon {
        @apply bg-peach h-[40px] w-[40px] mr-3 rounded-full text-center leading-[40px] text-[16px] text-white;
        svg {
            @apply top-[1px] left-[2px] relative;
        }
    }
    .action {
        .sublabel {
            @apply text-[13px] mb-0 leading-none;
        }
        .label {
            @apply text-[18px] mb-0 leading-none font-semibold;
        }
        *:first-child {
            @apply mb-1;
        }
    }
}

.blockTextRendered { 
    h2, h3, h4, h5, h6 {
        @apply mt-5 mb-3;
    }
    h2 {
        @apply text-peach text-[20px];
    }
    h3 {
        @apply text-[18px];
    }
    h4 {
        @apply text-[16px];
    }
    h5 {
        @apply text-[16px] text-gray;
    }
    h6 {
        @apply text-[15px] font-semibold uppercase;
    }
    p {
        @apply mb-3 font-light;
        a {
            @apply text-peach;
        }
        &.lead {
            @apply text-[17px];
        }
    }
    ul, ol {
       @apply list-inside;
       li {
        @apply font-light;
       }
    }
    ul {
        @apply list-disc;
    }
    ol {
        @apply list-decimal;
    }
    iframe {
        @apply !w-full !h-full aspect-video rounded-lg;
        
    }
    img {
        @apply mt-1;
        &.aligncenter {
            @apply mx-auto;
        }
    }
    strong {
        @apply font-semibold;
    }
    a {
        @apply text-peach underline;
    }
    .encard {
        @apply p-8 w-full rounded-lg my-4;
        *:last-child {
            @apply mb-0;
        }
        &.peachEncard {
            @apply bg-peach;
            * {
                @apply text-white;
            }
        }
        &.beigeEncard {
            @apply bg-beige;
        }
    }
} 

.searchForm {
    @apply w-full max-lg:bg-white/[.6] bg-white p-3 lg:p-4 lg:border lg:border-border rounded-lg flex items-center justify-start;
    .searchIcon {
        @apply mr-3;
    }
    .filterIcon {
        @apply ml-3;
    }
    .label {
        @apply opacity-50 text-[14px] truncate;
    }
    .searchInput {
        background-color: transparent;
        @apply flex-1 focus:outline-none;
    }
}

.SearchFilter {
    @apply h-dvh w-screen fixed top-0 left-0 z-50;

    @media (min-width: 992px) {
        width: calc(100vw - 300px);
        left: 300px;
    }

    .overlaypopUpSearch {
        @apply h-full w-full bg-black/[.8] backdrop-blur-sm;
    }
    
    .popUpSearch {
        @apply absolute left-0 bottom-0 w-full p-5 md:p-7 lg:p-10 pt-7 bg-body rounded-t-2xl;
        h2 {
            @apply mb-5
        }
        .searchForm {
            @apply mb-6;
        }
        .filtersSide {
            @apply relative overflow-x-hidden overflow-y-scroll max-h-[60dvh] pb-[35px];
            -ms-overflow-style: none;
                scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            &:after {
                content: '';
                bottom: calc(55px + 1rem + 1.25rem);
                @apply h-[40px] w-full bg-linear-gradient-v fixed left-0; 
            }
        }
        .BlockSearchType {
            @apply mb-6 -mx-1 last-of-type:mb-0 flex items-stretch justify-center flex-wrap;
            label {
                @apply flex-1 text-[16px] mx-1 border rounded-3xl border-gray-light text-gray py-2 px-3 text-center;
                input {
                    @apply absolute top-0 left-0 right-0 bottom-0 opacity-0 invisible;
                }
                &:has( input:checked){
                    @apply bg-peach border-peach text-white font-semibold;
                }
            }
        }
        .BlockSearch {
            @apply mb-5 last-of-type:mb-0 flex items-stretch justify-start flex-wrap;
            .label {
                @apply text-[16px] mb-3 font-semibold flex-[100%];
            }
            label {
                @apply relative text-[15px] w-fit mb-2 mr-2 border rounded-3xl border-gray-light text-gray py-1 px-3;
                input {
                    @apply absolute top-0 left-0 right-0 bottom-0 opacity-0 invisible;
                }
                &:has( input:checked){
                    @apply bg-peach/[.2] border-peach/[.6] text-peach;
                }
            }
        }
        .submitSearch {
           @apply mt-4;
        }
    }
}

.loadingScreen {
    @apply h-screen pb-[75px] flex items-center justify-center;
}

.blurredPattern {
    z-index: -1;
    @apply absolute h-[350px] w-[350px] md:h-[500px] md:w-[500px] lg:h-[600px] lg:w-[600px] rounded-full top-[50px] -left-[50px] md:-right-[100px] md:left-auto lg:-top-[100px] lg:-right-[100px];
    &.peach {
        @apply bg-radial-pattern-peach;
    }
    &.brown {
        @apply bg-radial-pattern-brown;
    }
    &.skin {
        @apply bg-radial-pattern-skin;
    }
    &.perle {
        @apply bg-radial-pattern-perle;
    }
    &.detail {
        @apply top-[400px]; 
    }
}

.pillsEnv {
    @apply bg-[#f79a0c] z-[99999] fixed top-0 left-0 w-screen h-[4px] flex items-start justify-center;
    span {
        @apply relative bg-[#f79a0c] p-[8px] pb-[6px] text-center rounded-b-lg text-white text-[10px] font-bold uppercase;
        &:before {
            content: '';
            @apply animate-ping opacity-75 relative inline-block h-[6px] w-[6px] rounded-full mr-[6px] top-[-1px] bg-white;
        }
        &:after {
            content: '';
            @apply absolute inline-block h-[6px] w-[6px] rounded-full top-[11.5px] left-[8px] bg-white;
        }
    }
    &.beta {
        @apply bg-[#23c333];
        span {
            @apply bg-[#23c333];
        }
    }
    &.local {
        @apply bg-[#1bc1c3];
        span {
            @apply bg-[#1bc1c3];
        }
    }
}